import React, {useEffect, useState} from 'react';
import {Card, Input as AntInput, Image, Upload, Select} from 'antd';
import './index.css';
import {CaretRightOutlined, FunnelPlotOutlined, AntDesignOutlined} from '@ant-design/icons';
import {Form, Button, Radio} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Col, Container, Row, Input, Table} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_green.css";
import Search from "antd/es/input/Search";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import {PopNotification} from "../../../shared/notification";
import {useCredentialStore} from "../../../hooks/useCredentialsStore";
import {
    addChargerProps,
    getChargePointBasedOnTextSearchBody,
    getChargePointBasedOnTextSearchResponse,
    getModelsBody,
    getModelsResponse,
    getSocketTypeBody,
    getSocketTypeResponse,
    getVendorBody,
    getVendorResponse, socketToShowBody,
    socketToSubmitBody
} from "../../../types/admin";
import {LoadingSpinner} from "../../../shared/LoadingSpinner";
import {getBase64} from "../../../utils/image";


const AddCharger: React.FC = ({children}) => {

    const { proxy } = require("../../../../package.json");
    const [uniqueId, setUniqueId] = useState("");
    // const [profileImageBase64, setProfileImageBase64] = useState("");
    const [profileImageId, setProfileImageId] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedChargePointId, setSelectedChargePointId] = useState("");
    const [selectedVendorId, setSelectedVendorId] = useState("");
    const [selectedModelId, setSelectedModelId] = useState("");
    const [chargePointLoading, setChargePointLoading] = useState<boolean>(false);
    const [mainChargePointList, setMainChargePointList] = useState<getChargePointBasedOnTextSearchBody[]>([])
    const [mainVendorList, setMainVendorList] = useState<getVendorBody[]>([])
    const [mainModelsList, setMainModelsList] = useState<getModelsBody[]>([])
    const [mainSocketTypeList, setMainSocketTypeList] = useState<getSocketTypeBody[]>([])
    const [socketListToSubmit, setSocketListToSubmit] = useState<socketToSubmitBody[]>([])
    const [socketListToShow, setSocketListToShow] = useState<socketToShowBody[]>([])
    const [form] = Form.useForm();
    const [socketTypeForm] = Form.useForm();
    const {token} = useCredentialStore(({token}) => ({
        token
    }));
    const {Option} = Select;

    const onSearch = (value: string) => {
        setChargePointLoading(true);
        axios
            .get((value !== "" || value != null || value !== undefined) ? `/api/chargePoints?page=0&pageSize=100&textSearch=${value}` : (`/api/chargePoints?page=0&pageSize=10`), {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnTextSearchResponse = res.data;
                console.log(chargePointList.data);
                setMainChargePointList(chargePointList.data);
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setChargePointLoading(false);
            });
    };

    const onAddCharger = () => {
        form.validateFields().then(values => {
            setLoading(true);

            const dataToSubmit: addChargerProps = {
                chargePointId: {
                    id: selectedChargePointId
                },
                modelId: {
                    id: selectedModelId
                },
                // uniqueId: (Math.random() + 1).toString(36).substring(7),
                vendorId: {
                    id: selectedVendorId
                },
                imageId: {
                    id: profileImageId
                },
                serialNumber: values.serialNumber,
                socketList: socketListToSubmit.map(({id, ...rest}) => rest),
                ocppChargerId:  values.ocppChargerId
            }

            axios
                .post("/api/charger", dataToSubmit, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    PopNotification("success", "Charger Created Successfully");
                    setUniqueId(res.data.uniqueId);
                })
                .catch((error) => {
                    PopNotification("error", error.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }

    const getVendorList = () => {
        axios
            .get("/api/charger/vendors?page=0&pageSize=100", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const vendorList: getVendorResponse = res.data;
                setMainVendorList(vendorList.data)
                // PopNotification("success", "Charge Point Created Successfully");
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const onAddSocket = () => {
        let uniqueId = uuidv4();
        socketTypeForm.validateFields().then(values => {
            setSocketListToSubmit([...socketListToSubmit, {
                chargerSocketTypeId: {
                    id: values.socketType.split("|")[0]
                },
                currentType: values.currentType,
                powerRating: parseFloat(values.powerRating),
                maxVoltage: values.maxVoltage,
                maxCurrent: values.maxCurrent,
                id: uniqueId,
                ocppConnectorId: parseInt(values.ocppConnectorId)
            }])
            setSocketListToShow([...socketListToShow, {
                // socketId: values.socketId,
                currentType: values.currentType,
                powerRating: parseFloat(values.powerRating),
                maxVoltage: values.maxVoltage,
                maxCurrent: values.maxCurrent,
                socketType: values.socketType.split("|")[1],
                id: uniqueId,
                ocppConnectorId: parseInt(values.ocppConnectorId)
            }])
        })
    }

    const onDeleteSocket = (socket_id?: string) => {
        setSocketListToSubmit(socketListToSubmit.filter(socket => socket.id !== socket_id))
        setSocketListToShow(socketListToShow.filter(socket => socket.id !== socket_id))

        // socketTypeForm.validateFields().then(values => {
        //     setSocketListToSubmit([...socketListToSubmit, {
        //         chargerSocketTypeId: {
        //             id: values.socketType.split("|")[0]
        //         },
        //         currentType: values.currentType,
        //         powerRating: parseFloat(values.powerRating)
        //         // uniqueId: (Math.random() + 1).toString(36).substring(7)
        //     }])
        //     setSocketListToShow([...socketListToShow, {
        //         // socketId: values.socketId,
        //         currentType: values.currentType,
        //         powerRating: parseFloat(values.powerRating),
        //         socketType: values.socketType.split("|")[1]
        //     }])
        // })
    }

    const getModelsList = () => {
        axios
            .get((selectedVendorId !== "" || selectedVendorId != null || selectedVendorId !== undefined) ? `/api/charger/models?page=0&pageSize=100&vendorId=${selectedVendorId}` : `/api/charger/models?page=0&pageSize=100`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const modelsList: getModelsResponse = res.data;
                setMainModelsList(modelsList.data)
                // PopNotification("success", "Charge Point Created Successfully");
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const getSocketTypeList = () => {
        axios
            .get((selectedModelId !== "" || selectedModelId != null || selectedModelId !== undefined) ? `/api/charger/socketTypes?page=0&pageSize=10&modelId=${selectedModelId}` : `/api/charger/socketTypes?page=0&pageSize=10`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const socketTypeList: getSocketTypeResponse = res.data;
                setMainSocketTypeList(socketTypeList.data)
                // PopNotification("success", "Charge Point Created Successfully");
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    useEffect(() => {
        getVendorList()
    }, [])

    useEffect(() => {
        if (selectedVendorId !== "") {
            getModelsList()
        }
    }, [selectedVendorId])

    useEffect(() => {
        if (selectedModelId !== "") {
            getSocketTypeList()
        }
    }, [selectedModelId])

    useEffect(() => {
        axios
            .get(`/api/chargePoints?page=0&pageSize=999`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                const chargePointList: getChargePointBasedOnTextSearchResponse = res.data;
                console.log(chargePointList.data);
                setMainChargePointList(chargePointList.data);
            })
            .catch((error) => {
                PopNotification("error", error.response.data.message);
            })
            .finally(() => {
                setChargePointLoading(false);
            });
    }, []);


    return (
        <div>
            <span className='page-title'>Add new charger</span>
            <Container fluid={true} style={{paddingLeft: 0, paddingRight: 0}}>
                <Form form={form}
                      layout="vertical"
                      onFinish={onAddCharger}>
                    <Row>
                        <Col md={6}>
                            <div style={{marginTop: 20,}}>
                                {/*<span className='heading-page'>Charge Point Profile Image</span>*/}
                                <Upload
                                    accept="image/png, image/jpeg"
                                    maxCount={1}
                                    // listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(file: any) => {
                                        console.log(file)
                                        const isLt2M = file.file.size / 1024 / 1024 < 500;
                                        if (isLt2M) {
                                            getBase64(file.file).then((base64String: any) => {
                                                const dataToSubmit = {
                                                    base64: base64String,
                                                }

                                                axios
                                                    .post("/api/image", dataToSubmit, {
                                                        headers: {
                                                            Authorization: `Bearer ${token}`
                                                        }
                                                    })
                                                    .then((res) => {
                                                        setProfileImageId(res.data.id.id)
                                                        PopNotification("success", "Profile Image Uploaded");
                                                    })
                                                    .catch((error) => {
                                                        PopNotification("error", error.response.data.message);
                                                    })
                                            });

                                        } else {
                                            PopNotification("error", "Image size should not be greater than 500kb size");
                                        }

                                    }} // to override the component sending request on image upload, see https://stackoverflow.com/a/51519603/4858751
                                    beforeUpload={() => console.log("before upload")} // see https://ant.design/components/upload/#components-upload-demo-avatar
                                    onChange={() => console.log("handle change")} // see https://ant.design/components/upload/#components-upload-demo-avatar
                                >
                                    {profileImageId ? (
                                        <Image
                                            preview={false}
                                            width={200}
                                            src={`/api/image/` + profileImageId}
                                        />
                                    ) : <Button block type="primary" className="login-form-button">Upload Profile
                                        Image</Button>}
                                </Upload>
                            </div>
                            <div style={{marginTop: 20,}}>
                                { uniqueId !== "" && <span className='id-css'>Unique ID: {uniqueId}</span> }
                                <div style={{margin: "10px 0px",}}>
                                    <Form.Item
                                        name="name"
                                    >
                                        <Input
                                            placeholder="Charge Point"
                                            className='input-box'
                                            onChange={(e) => {
                                                onSearch(e.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <span className='id-css'>Selected Charge Point: {selectedChargePointId}</span>
                                <div style={{margin: "5px 13px"}}>
                                    <Row md={12} style={{marginTop: 10}}>
                                        <Col md={12} className='card-datavisulization'
                                             style={{textAlign: "left", padding: 20, height: "auto"}}>
                                            {
                                                chargePointLoading ? (
                                                    <LoadingSpinner/>
                                                ) : (
                                                    <Table bordered responsive>
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                Charge Point
                                                            </th>
                                                            <th>
                                                                Address
                                                            </th>
                                                            <th>
                                                                Access
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            mainChargePointList?.map((chargePointDetails) => (
                                                                <tr>
                                                                    <td>
                                                                        {chargePointDetails.name}
                                                                    </td>
                                                                    <td>
                                                                        {chargePointDetails.name} {chargePointDetails.name}
                                                                    </td>
                                                                    <td style={{textAlign: "center"}}>
                                                                        <Button block type="primary" htmlType="button"
                                                                                className={"charger-assign-button"}
                                                                                onClick={() => setSelectedChargePointId(chargePointDetails.id.id)}>
                                                                            Assign Access
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </Table>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    selectedChargePointId !== "" && (
                                        <>
                                            <span className='heading-page'>General Charger Details</span>
                                            <div style={{margin: "10px 0px",}}>
                                                <Input
                                                    id="exampleSelect1"
                                                    name="select"
                                                    type="select"
                                                    placeholder='Vendor'
                                                    onChange={(e) => setSelectedVendorId(e.target.value)}
                                                >
                                                    <option value={"no data"}>
                                                        Please Select Vendor
                                                    </option>
                                                    {
                                                        mainVendorList.length > 0 && (
                                                            mainVendorList.map((vendorDetails) => (
                                                                <option value={vendorDetails.id.id}>
                                                                    {vendorDetails.name}
                                                                </option>
                                                            ))
                                                        )
                                                    }
                                                </Input>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Input
                                                    id="exampleSelect2"
                                                    name="select"
                                                    type="select"
                                                    placeholder='Model'
                                                    onChange={(e) => setSelectedModelId(e.target.value)}
                                                >
                                                    <option value={"no data"}>
                                                        Please Select Model
                                                    </option>
                                                    {
                                                        mainModelsList.length > 0 ? (
                                                                mainModelsList.map((modelDetails) => (
                                                                    <option value={`${modelDetails.id.id}`}>
                                                                        {modelDetails.name}
                                                                    </option>
                                                                ))
                                                            )
                                                            : (
                                                                <option value={"no data"}>
                                                                    No Data
                                                                </option>
                                                            )
                                                    }
                                                </Input>
                                            </div>
                                            <div style={{margin: "10px 0px",}}>
                                                <Form.Item
                                                    name="serialNumber"
                                                    rules={[{required: true, message: 'Please input Serial No!'}]}
                                                >
                                                    <Input
                                                        placeholder="Serial No"
                                                        className='input-box'
                                                        onChange={(e) => {

                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{margin: "0",}}>
                                                <Form.Item name='ocppChargerId'
                                                           rules={[{required: false}]}
                                                >
                                                    <Input
                                                        placeholder="OCPP Charger Id. Leave Empty if auto assigning..."
                                                        className='input-box'
                                                        defaultValue=""
                                                    />
                                                </Form.Item>

                                            </div>
                                            <span className='heading-page'>Charging socket</span>
                                            <Form form={socketTypeForm}
                                                  layout="vertical"
                                                  onFinish={onAddSocket}>
                                                <Row md={12} style={{margin: 1}}>
                                                    <Col md={12} className='card-datavisulization'
                                                         style={{textAlign: "left", paddingTop: 10, height: "auto"}}>
                                                        <Table bordered responsive>
                                                            <thead>
                                                            <tr>
                                                                {/*<td>*/}
                                                                {/*    Socket Id*/}
                                                                {/*</td>*/}
                                                                <th>
                                                                    Socket Type
                                                                </th>
                                                                <th>
                                                                    Current Type
                                                                </th>
                                                                <th>
                                                                    Power Rating (kW)
                                                                </th>
                                                                <th>
                                                                    Max Voltage (V)
                                                                </th>
                                                                <th>
                                                                    Max Ampere (A)
                                                                </th>
                                                                <th>
                                                                    OCPP Connector Id
                                                                </th>
                                                                <th>
                                                                    Action
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                socketListToShow?.map((socketTypeDetails) => (
                                                                    <tr>
                                                                        {/*<td>*/}
                                                                        {/*    {socketTypeDetails.socketId}*/}
                                                                        {/*</td>*/}
                                                                        <td>
                                                                            {socketTypeDetails.socketType}
                                                                        </td>
                                                                        <td>
                                                                            {socketTypeDetails.currentType}
                                                                        </td>
                                                                        <td>
                                                                            {socketTypeDetails.powerRating}
                                                                        </td>
                                                                        <td>
                                                                            {socketTypeDetails.maxVoltage}
                                                                        </td>
                                                                        <td>
                                                                            {socketTypeDetails.maxCurrent}
                                                                        </td>
                                                                        <td>
                                                                            {socketTypeDetails.ocppConnectorId}
                                                                        </td>
                                                                        <td>
                                                                            <Button block type="ghost" htmlType="button"
                                                                                    onClick={() => onDeleteSocket(socketTypeDetails.id)}>
                                                                                Remove
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            <tr>
                                                                {/*<td>*/}
                                                                {/*    <Form.Item*/}
                                                                {/*        name="socketId"*/}
                                                                {/*        rules={[{*/}
                                                                {/*            required: true,*/}
                                                                {/*            message: 'Please input Socket ID!'*/}
                                                                {/*        }]}*/}
                                                                {/*    >*/}
                                                                {/*        <AntInput*/}
                                                                {/*            placeholder="Socket Id"*/}
                                                                {/*            className='input-box'*/}
                                                                {/*            onChange={(e) => {*/}
                                                                {/*                // onSearch(e.target.value)*/}
                                                                {/*            }}*/}
                                                                {/*        />*/}
                                                                {/*    </Form.Item>*/}
                                                                {/*</td>*/}
                                                                <td>
                                                                    <Form.Item
                                                                        name="socketType"
                                                                    >
                                                                        <Select>
                                                                            {
                                                                                mainSocketTypeList.length > 0 && (
                                                                                    mainSocketTypeList.map((socketTypeDetails) => (
                                                                                        <Option
                                                                                            value={`${socketTypeDetails.id.id}|${socketTypeDetails.name}`}>{socketTypeDetails.name}</Option>
                                                                                    ))
                                                                                )
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        name="currentType"
                                                                    >
                                                                        <Select>
                                                                            <Option
                                                                                value={`AC_1_PHASE`}>AC_1_PHASE</Option>
                                                                            <Option
                                                                                value={`AC_3_PHASE`}>AC_3_PHASE</Option>
                                                                            <Option value={`DC`}>DC</Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        name="powerRating"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Please input power rating!'
                                                                        }]}
                                                                    >
                                                                        <AntInput
                                                                            placeholder="Power Rating"
                                                                            className='input-box'
                                                                            onChange={(e) => {
                                                                                // onSearch(e.target.value)
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        name="maxVoltage"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Please input max voltage!'
                                                                        }]}
                                                                    >
                                                                        <AntInput
                                                                            placeholder="Max Voltage"
                                                                            className='input-box'
                                                                            onChange={(e) => {
                                                                                // onSearch(e.target.value)
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        name="maxCurrent"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Please input max current!'
                                                                        }]}
                                                                    >
                                                                        <AntInput
                                                                            placeholder="Max Current"
                                                                            className='input-box'
                                                                            onChange={(e) => {
                                                                                // onSearch(e.target.value)
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item
                                                                        name="ocppConnectorId"
                                                                        rules={[{
                                                                            required: true,
                                                                            message: 'Please input ocpp connector Id!'
                                                                        }]}
                                                                    >
                                                                        <AntInput
                                                                            placeholder="OCPP Connector Id"
                                                                            className='input-box'
                                                                            onChange={(e) => {
                                                                                // onSearch(e.target.value)
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Button block type="primary" htmlType="button"
                                                                            onClick={onAddSocket}>
                                                                        Add
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            {
                                                loading ? (
                                                    <LoadingSpinner/>
                                                ) : (
                                                    <Button block type="primary" htmlType="submit"
                                                            className="login-form-button">
                                                        Add Charger
                                                    </Button>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    )
}

export default AddCharger
